/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	$(function () {
		
		$('.slider-section').flexslider({
			controlNav: true,
			directionNav: false,
		});
		
		$(".slide-prev").click(function(e) {
			e.preventDefault();
			$('.slider-section').flexslider('prev');
		});
		$(".slide-next").click(function(e) {
			e.preventDefault();
			$('.slider-section').flexslider('next');
		});
/*
		$('#slider-thumbnails').flexslider({
		    animation: "slide",
		    controlNav: false,
		    animationLoop: false,
		    slideshow: false,
		    itemWidth: 100,
		    itemMargin: 5,
			sync: '#slider-gallery'
		});
*/
		
		$('#slider-gallery').flexslider({
		    animation: "slide",
		    controlNav: !false,
		    directionNav: false,
		    animationLoop: false,
		    slideshow: false,
		    prevText: '',
		    nextText: '',
// 			asNavFor: '#slider-thumbnails'
		});
		
		
		$('.halfslider').flexslider({
			animation: "slide",
			slideshowSpeed: 4000, 
			animationSpeed: 700,
			touch: true, 
			controlsContainer: $(".nav-container-halfslider"),
			after:  function(slider){
				 
				var current = $('.halfslider').find(".flex-active-slide");
				$(".halfslider-slide-text").css("opacity", "1");
				$(".halfslider-slide-text").html(current.data("slide_text"));  
			},
			before:  function(slider){
				$(".halfslider-slide-text").css("opacity", "0");
			}, 
			start: function(slider){
				var current = $('.halfslider').find(".flex-active-slide");
				 
				$(".halfslider-slide-text").html(current.data("slide_text"));  
			}
		  });
		
		$(".half-prev").click(function(e) {
			e.preventDefault(); 
			$('.halfslider').flexslider('prev');
			
		});
		$(".half-next").click(function(e) {
			e.preventDefault(); 
			$('.halfslider').flexslider('next');
			
		});
		
		
		if(typeof instaToken !== 'undefined' && instaToken.length != 0)
		{
			var $containerwidth = $(window).width();
			if($containerwidth < 920) {
				instaImages = 3;
			}
			if($containerwidth < 768) {
				instaImages = 2;
			}
			$(".instastream-container").instastream({
				instaToken: instaToken,// More information on http://instagram.com/developer/
				instaUser: instaUser,//
				instaResults: instaImages,
				instaMenu: 'yes'
			});
		}
		
		$('.gotoup').click(function() {
			
			jQuery('html,body').animate({
	          scrollTop: 0
	        }, 1000);
	        
	        return false;
		});
		
		if(typeof dataGalleriaImages !== 'undefined' && dataGalleriaImages.length != 0)
		{ 
			
			$(".single-gallery-image").click(function() {
				$("body").css("overflow", "hidden");
				$h = $( window ).height();			
				$(".galleria").css("height", $h+"px");
				$(".galleria").css("display", "block");
				
				 
				$(".galleria-close").css("display", "block"); 
				var $elNum = $(this).data("gelement");
			
				Galleria.run('.galleria', {  
					transition: 'fade',
					dataSource: dataGalleriaImages 
					//show: $elNum;
				});
				//Galleria.get($elNum).play();
				//if(parseInt($elNum) != 0){
					Galleria.configure("show", $elNum);
			//	}
				
			});
			$(".galleria-close").click(function() {
				$("body").css("overflow", "auto");
				$(".galleria").css("display", "none");
				$(".galleria-close").css("display", "none"); 
			});
		}


	});


})(jQuery);
